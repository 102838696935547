import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export interface Props {data:any}
export const InquiryList:React.FC<Props> = ({data}) => {
    const navigate = useNavigate()
    const groupId = sessionStorage.getItem("GroupCode");
    
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr className="active" key={list?.inquiry_idx} onClick={()=>navigate(`/dotsAdmin/reporting/inquiry/${list?.inquiry_idx}`)}>
                
                <td>{list?.inquiry_company_name}</td>
                
                <td>{list?.inquiry_name}</td>

                <td dangerouslySetInnerHTML={{__html : list?.inquiry_type}}></td>

                <td>{list?.inquiry_email}</td>

                <td>{list?.inquiry_phone}</td>

                <td>{list?.inquiry_mdate}</td>

                <td>{list?.inquiry_reply_status === 'U' ? '답변대기' : '답변완료'}</td>

            </tr>
            ))}
    
        </tbody>
        </>
    )
}