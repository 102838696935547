import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavigationBox } from "../../../../components/subPage/navBox";
import { useSetInquiryMutation } from "../../../../store/user/contentsApiSlice";
import { getCookie } from "../../../../util/cookie/cookies";

export const ContactUs = () => {
    const {t}  = useTranslation()
    const [setInquiryApi] = useSetInquiryMutation()
    const lang = getCookie('BINEXLANG')
    const initData = {companyName:'', name:'', position:'', email:'',phone:'',question:'', contents:'',files:[],fileName:[], agree : false, lang : 'KR'}
    const [data, setData] = useState<any>(initData)
    const handleTextChange = (e:any) => {
        const {value, name} = e.target; 
        setData((prev:any) => ({...prev, [name] : value}))
    }
    const handleQuestSelect = (value:string) => {
        console.log(value)
        setData((prev:any) => ({...prev, question : value}))
    }
    const handleFiles = (e:any) => {
        const files = e.target.files;
        const filesArray = Array.from(files);
        const fileNames = filesArray.map((file: any) => file.name);
        setData((prev : any) => ({ ...prev, files: filesArray , fileName : fileNames}));
    }
    const handleDelFile = (file:any) => {
        const updatedFiles = data.files.filter((files:any) => files.name !== file);
        const updatedFileNames = updatedFiles.map((files : any) => files.name);
        setData((prev:any) => ({
            ...prev,
            files: updatedFiles,
            fileName: updatedFileNames,
        }));
    }
    const handleCheckChange = (e:any) => {
        const {checked} = e.target;
        setData((prev:any) => ({...prev, agree : checked}))
    }
    const dataCheck = data.companyName === '' || data.name === '' || data.postion === '' || data.email === '' || data.position === '' || data.phone === '' || data.question === '' || data.contents === '';
    const agreeCheck = data.agree === false;
    const handleSubmit = async() => {
        if(dataCheck){
            alert("모든 항목이 필수 입력 사항입니다."); return;
        } else if(agreeCheck){
            alert("개인 정보 수집 및 이용에 대한 동의를 체크해주세요."); return;
        } else{
            const formData:any = new FormData();
            formData.append('inquiry_company_name', data.companyName)
            formData.append('inquiry_name', data.name)
            formData.append('inquiry_position', data.position)
            formData.append('inquiry_email', data.email)
            formData.append('inquiry_phone', data.phone)
            formData.append('inquiry_type', data.question)
            formData.append('inquiry_content', data.contents)
            formData.append('inquiry_lang', data.lang)
            if(data?.files?.length !== 0){
                data?.files?.forEach((file:File, index : number) => {
                    formData.append(`inquiry_files_${index+1}`, file)
                })
            }
            if(data?.files?.length !==0){formData.append('files_length', data?.files?.length)}
            const result : any = await setInquiryApi(formData)
            if(result.data.result === true){alert("문의접수가 되었습니다."); window.scrollTo({top :0 , behavior : 'auto'});}
        }
    }
    useEffect(()=>{
        setData((prev:any)=>({...prev, lang : lang === 'Kr'?'KR':'EN'}))
    }, [lang])
    return(
        <>
        <div className="subPage contactUs">
            <div className="banner_area">
                <div>
                    <h2>{lang==='Kr'?'문의하기':'Inquiry'}</h2>
                </div>
            </div>

            <NavigationBox/>

            <div className="contentBox">
                <h3>{t("contactus_1")}</h3>
                <span>* {t("contactus_2")}</span>

                    <fieldset>
                        <div>
                            <h4>
                                <span>1</span>
                                {t("contactus_3")} <span className="red">*</span>
                            </h4>
                            <ul>
                                <li>
                                    <div className="inputBox">
                                        <input type="text" name="companyName" id="companyName" onChange={handleTextChange} required/>
                                        <label htmlFor="companyName">
                                            <span>{t("contactus_4")}</span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="inputBox">
                                        <input type="text" name="name" id="customerName" onChange={handleTextChange} required/>
                                        <label htmlFor="customerName">
                                            <span>{t("contactus_5")}</span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="inputBox">
                                        <input type="text" name="position" id="rank" onChange={handleTextChange} required/>
                                        <label htmlFor="rank">
                                            <span>{t("contactus_6")}</span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="inputBox">
                                        <input type="text" name="email" id="mail" onChange={handleTextChange} required/>
                                        <label htmlFor="mail">
                                            <span>{t("contactus_7")}</span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="inputBox">
                                        <input type="text" name="phone" id="phone" onChange={handleTextChange} required/>
                                        <label htmlFor="phone">
                                            <span>{t("contactus_8")}</span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- 1. 문의하시는 고객님의 정보를 기입해 주세요 --> */}
                        
                        <div>
                            <h4>
                                <span>2</span>
                                {t("contactus_9")} <span className="red">*</span>
                            </h4>
                            <ul>
                                {QuestionArray.map((list:any) => (
                                <li key={list.idx} onClick={()=>handleQuestSelect(list?.value)}>
                                    <div>
                                        <input type="radio" name="inquiry" id={list.title_kr}/>
                                        <label htmlFor={list.title_kr}>
                                            <span dangerouslySetInnerHTML={{__html : lang==='Kr'?list?.title_kr:list?.title_en}}>
                                                
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                ))}
                            </ul>
                        </div>
                        {/* <!-- 2. 어떤 것이 궁금하신가요? --> */}

                        <div>
                            <h4>
                                <span>3</span>
                                {t("contactus_10")} <span className="red">*</span>
                            </h4>
                            <div>
                                <textarea name="contents" id="" placeholder={lang==='Kr'?'궁금하신 점을 자세히 적어주세요.':'Please provide details of your inquiry.'} onChange={handleTextChange}></textarea>
                            </div>
                        </div>
                        {/* <!-- 3. 문의하실 내용을 자유롭게 적어주세요. --> */}

                        <div>
                            <h4>
                                <span>4</span>
                                {t("contactus_11")}
                            </h4>
                            
                            <div className="fileUploadBox">
                                <div>
                                    <input type="file" name='files' id="fileUpload" multiple onChange={handleFiles}/>
                                    <label htmlFor="fileUpload">{t("contactus_12")}</label>
                                </div>
                                <p>{t("contactus_13")}</p>
                            </div>
                            <ul>
                                {data?.fileName.map((file:any) => (
                                <li>
                                    <input type="text" name="" id="" value={file} readOnly/>
                                    <button onClick={()=>handleDelFile(file)}></button>
                                </li>
                                ))}
                            </ul>
                        </div>
                        {/* <!-- 4. 파일을 업로드해 주세요. --> */}

                        <div>
                            <div>
                                <input type="checkbox" name="agree" id="agree" onChange={handleCheckChange}/>
                                <label htmlFor="agree">{t("contactus_14")}</label>
                            </div>
                            <p>
                            {t("contactus_15")}<br/>
                            {t("contactus_16")}<br/>
                            {t("contactus_17")}
                            </p>
                        </div>
                        {/* <!-- 개인 정보 수집 및 이용에 대해 동의합니다. --> */}

                        <div>
                            <button onClick={handleSubmit}>{t("contactus_18")}</button>
                        </div>
                    </fieldset>

            </div>

        </div>
        </>
    )
}

export const QuestionArray = [
    {idx: 1, title_kr : '바이오의약품<br/>CDMO문의', title_en : 'Biologics CDMO<br/>Inquiry', value : "CDMO문의"},
    {idx: 2, title_kr : '합성의약품<br/>제품문의', title_en : 'Chemical Product<br/>Inquiry', value : "제품문의"},
    {idx: 3, title_kr : '합성의약품<br/>수탁문의', title_en : 'Consignment Inquiry', value : "수탁문의"},
    {idx: 4, title_kr : '채용문의', title_en : 'Recruitment Inquiry', value : "채용문의"},
    {idx: 5, title_kr : '투자문의', title_en : 'Investment Inquiry', value : "투자문의"},
]